<svelte:options tag="video-block"/>

<script>
    import { onMount } from 'svelte';

    export let hash = 'unknown';
    let _hash = typeof hash === 'string' ? hash : 'unknown';
    let _videos = videos.filter((value, _) => value.hash == _hash); // Creates an array with only the video has this specific hash
    let video_html = _videos[0].video; // Used to render video in @html tag
    let isVideoPlayed = false;
    let video_alt_text = _videos[0].video_alt_text;
    let videoContainer;
    let video;

    onMount(() => {
        _videos = images.filter((value, _) => value.hash == _hash);
        video = videoContainer.querySelector('video');
        video.setAttribute("title", video_alt_text);
    });

    function playVideo() {
        video.play();
        video.setAttribute("controls", true);
        video.setAttribute("controlsList", "nodownload");
        isVideoPlayed = true;
    }
</script>

<section class="imageblock__video" bind:this={videoContainer}>
    {@html video_html.outerHTML}
    <div class="imageblock__video__overlay flex items-center justify-center" class:hidden={isVideoPlayed}>
        <button class="imageblock__video__play-button" aria-label="Spill av video" on:click={() => playVideo()}>
            <i class="fa-sharp fa-solid fa-play"></i>
        </button>
    </div>
</section>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>
